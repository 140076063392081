import { useState } from "react";
import { InputField } from "../components";
import { Button } from "../components/Button";
import { useBackButton } from "../components/Header";
import { Page } from "../components/Page";
import { useUserRegistration } from "../hooks/useUsers";
import { useToast } from "../providers/ToastProvider";
import { useUserContext } from "../providers/UserProvider";
import { Link } from "react-router-dom";

export const LoginRegisterPage: React.FC = () => {
  useBackButton({
    show: true,
  });
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { loggedInUser, logout, login } = useUserContext();
  const { showToast } = useToast();

  const [, registerUser] = useUserRegistration(
    () => {
      showToast("You are now registered");
    },
    (err) => showToast(JSON.stringify(err))
  );

  const onRegister = () => {
    registerUser({ userName, password });
  };

  const onLogin = () => {
    login({ userName, password });
  };

  return (
    <Page>
      <div>
        <h3>Status</h3>
        {loggedInUser
          ? `Logged in as "${loggedInUser.userName}"`
          : "Logged out"}
      </div>
      {loggedInUser && (
        <Button
          onClick={() => {
            logout();
          }}
        >
          Logout
        </Button>
      )}
      <div>
        <h3>Login</h3>
        <InputField
          placeHolder="Username"
          autoCapitalize="none"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <InputField
          placeHolder="Password"
          autoCapitalize="none"
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={onLogin}>Login</Button>
        <Link to={"/generate-reset-password"}>
          Reset password (in progress)
        </Link>
      </div>
      <div>
        <h3>Registration</h3>
        <InputField
          placeHolder="Username"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <InputField
          placeHolder="Password"
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={onRegister}>Register</Button>
      </div>
    </Page>
  );
};
