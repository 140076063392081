import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { HeaderMenu2 } from "../components/HeaderMenu2";
import { BackButtonState, emitter, EmmiterEventType } from "../EventEmmiter";
import { useUserContext } from "../providers/UserProvider";

const HeaderTitle = styled.span`
  font-size: x-large;
  margin-bottom: 10px;
  color: #ac3a2e;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const UserLink = styled(Link)`
  display: flex;
  justify-content: center;
`;

const UserStatus = styled.div`
  display: flex;
  justify-content: center;
`;
const LogoutLink = styled.div`
  text-decoration: underline;
  color: blue;
  cursor: pointer;
`;
const QuickLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
`;

const TitleAndMenuContainer = styled.div`
  position: relative;
  padding: 20px;
  background: #dadab0;
  border: solid 1px #e7eaad;
`;

const BackButtonIcon = styled.div`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  margin: auto;
  margin-right: 5px;
`;

const BackButton = styled.button`
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
  margin-right: 10px;
`;

const UserActionComponent = styled.div`
  margin: auto 0;
`;

export const useBackButton = (state: BackButtonState): boolean =>
  emitter.dispatchEvent(
    new CustomEvent<BackButtonState>(EmmiterEventType.BackButton, {
      detail: { ...state },
    })
  );

const SHOW_BACK_DEFAULT = true;

export const Header: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { loggedInUser, logout } = useUserContext();
  const navigate = useNavigate();
  const [showBackButton, setShowBackButton] = useState(SHOW_BACK_DEFAULT);
  useEffect(() => {
    const showBackButton = (e: CustomEvent) => {
      const state: BackButtonState = e.detail;
      setShowBackButton(state.show === undefined ? true : state.show);
    };
    emitter.addEventListener(
      EmmiterEventType.BackButton,
      showBackButton as EventListener
    );
    return () => {
      emitter.removeEventListener(
        EmmiterEventType.BackButton,
        showBackButton as EventListener
      );
    };
  }, []);
  const onBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  return (
    <>
      <HeaderContainer>
        <TitleAndMenuContainer>
          <Link to={"/"}>
            <HeaderTitle>Read-Write-Imagine</HeaderTitle>
          </Link>
          <HeaderMenu2 />
        </TitleAndMenuContainer>
        <QuickLinksContainer>
          {showBackButton && (
            <BackButton onClick={onBackClick}>
              <BackButtonIcon /> Back
            </BackButton>
          )}
          <UserActionComponent>
            {loggedInUser ? (
              <UserStatus>
                Hello&nbsp;
                <Link to={"/user-dashboard"}>{loggedInUser.userName}</Link>! (
                <LogoutLink
                  role={"button"}
                  onClick={() => {
                    logout();
                  }}
                >
                  logout
                </LogoutLink>
                )
              </UserStatus>
            ) : (
              <UserLink to={"/login-register"}>Login/Register</UserLink>
            )}
          </UserActionComponent>
        </QuickLinksContainer>
      </HeaderContainer>
      {children}
    </>
  );
};
