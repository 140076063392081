import { useAsync } from "react-use";
import { Page, Story, User } from "../models/gateway";
import {
  canUserAddPage,
  canUserDeletePage,
  canUserDeleteStory,
  canUserUpdatePage,
  canUserUpdateStory,
} from "../utils/authUtils";

export const useAuthUtils = (
  user: User | null,
  story: Story | undefined,
  page?: Page
) => {
  return useAsync(async () => {
    const userId = user?.id;
    const payload = { loggedInId: userId!, story: story!, page };
    const usResult = !!story && !!userId && (await canUserUpdateStory(payload));
    const dsResult = !!story && !!userId && (await canUserDeleteStory(payload));

    const apResult = !!story && !!userId && (await canUserAddPage(payload));
    const upResult =
      !!story && !!userId && !!page && (await canUserUpdatePage(payload));
    const dpResult =
      !!story && !!userId && !!page && (await canUserDeletePage(payload));
    return {
      canUserAddPage: apResult,
      canUserUpdatePage: upResult,
      canUserDeletePage: dpResult,
      canUserUpdateStory: usResult,
      canUserDeleteStory: dsResult,
    };
  }, [story, page, user]);
};
