import React, { useId } from "react";
import styled from "styled-components";

const InputFieldContainer = styled.div`
  position: relative;
  line-height: 44px;
  height: 44px;
  width: 250px;
  padding: 10px 0;
  /* background-color: white;
  border-radius: 10px; */
`;
const InputInput = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  padding: 1rem 0.5rem;
  border-bottom: 2px solid #d3d3d3;
  box-shadow: none;
  color: #111;
  border-radius: 10px;
  :invalid {
    outline: 0;
    // color: #ff2300;
    // border-color: #ff2300;
  }

  :focus,
  :valid {
    /* border-color: #00dd22; */
  }

  :focus ~ label,
  :valid ~ label {
    font-size: 12px;
    top: -4px;
    /* color: #00dd22; */
  }
`;
const InputLabel = styled.label`
  position: absolute;
  top: 10px;
  left: 0.5rem;
  width: 100%;
  color: #000b;
  transition: 0.2s all;
  cursor: text;
`;

export type InputFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  placeHolder: string;
};
export const InputField: React.FC<InputFieldProps> = ({
  placeHolder,
  ...rest
}) => {
  const id = useId();
  return (
    <InputFieldContainer>
      <InputInput autoCapitalize="none" required id={id} {...rest} />
      <InputLabel htmlFor={id}>{placeHolder}</InputLabel>
    </InputFieldContainer>
  );
};
