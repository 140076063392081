import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Header } from "./components/Header";
import {
  Dashboard,
  StoriesDashboard,
  DemoPage,
  LoginRegisterPage,
  PageDetailsPage,
  AboutPage,
  GenerateResetPasswordPage,
  ResetPasswordPage,
} from "./pages";
import { CreatePagePage } from "./pages/CreatePagePage";
import { StoryDetailsPage } from "./pages/StoryDetailsPage";
import { UserDashboard } from "./pages/UserDashboard";
import { UserList } from "./pages/UserList";
import { LoaderProvider } from "./providers/LoaderProvider";
import { ToastProvider } from "./providers/ToastProvider";
import { UserProvider } from "./providers/UserProvider";

function App() {
  return (
    <BrowserRouter>
      <LoaderProvider>
        <ToastProvider>
          <UserProvider>
            <Header>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/stories" element={<StoriesDashboard />} />
                <Route path="/login-register" element={<LoginRegisterPage />} />
                <Route
                  path="/generate-reset-password"
                  element={<GenerateResetPasswordPage />}
                />
                <Route
                  path="/reset-password/:userId/:resetId"
                  element={<ResetPasswordPage />}
                />
                <Route path="/user-dashboard" element={<UserDashboard />} />
                <Route path="/user-list" element={<UserList />} />
                <Route path="/story/:storyId" element={<StoryDetailsPage />} />
                <Route path="/new-page/:storyId" element={<CreatePagePage />} />
                <Route
                  path="/new-page/:storyId/:prevPageId/:choiceId"
                  element={<CreatePagePage />}
                />
                <Route
                  path="/page/:storyId/:pageId"
                  element={<PageDetailsPage />}
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Header>
          </UserProvider>
        </ToastProvider>
      </LoaderProvider>
    </BrowserRouter>
  );
}

export default App;
