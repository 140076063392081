import { Page } from "../models/gateway";
import { doApiCall } from "../utils/apiUtils";

export const getPage = async (pageId: string) => {
  return await doApiCall<Page>({
    method: "get",
    url: `dev/page/${pageId}`,
  }); // pageId
};

export const createPage = async (
  storyId: string,
  text: string,
  prevPageId?: string,
  choiceId?: string
) => {
  return await doApiCall<string>({
    method: "post",
    url: "dev/page/create",
    data: {
      storyId,
      text,
      previousPageId: prevPageId,
      choiceId,
    },
  }); // pageId
};

export const updatePageContent = async (pageId: string, text: string) => {
  return await doApiCall<string>({
    method: "post",
    url: "dev/page/editContent",
    data: {
      pageId,
      text,
    },
  }); // pageId
};
