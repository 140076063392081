import { useState } from "react";
import styled from "styled-components";
import { register } from "../../gateways/usersGateway";
import { useUsers } from "../../hooks/useUsers";

const ModuleContainer = styled.div``;
const SectionHeader = styled.h1``;
const DataSection = styled.div``;
const DataTitle = styled.div``;
const DataRow = styled.div``;
const UserListContainer = styled.div`
  border: black 1px solid;
`;
const UserListItem = styled.div`
  cursor: pointer;
  :hover {
    background-color: gray;
  }
`;

export const UserModule: React.FC = () => {
  const { value: userList } = useUsers();
  const [registerInput, setRegisterInput] = useState("");
  return (
    <ModuleContainer>
      <SectionHeader>User Module</SectionHeader>
      <DataSection>
        <DataTitle>Users</DataTitle>
        <DataRow>
          <UserListContainer>
            {userList &&
              userList.map((u, uI) => (
                <UserListItem key={uI}>{u.userName}</UserListItem>
              ))}
          </UserListContainer>
        </DataRow>
      </DataSection>
      <DataSection>
        <DataTitle>Register</DataTitle>
        <DataRow>
          <input
            value={registerInput}
            onChange={(e) => setRegisterInput(e.target.value)}
          />
          <button
            onClick={() =>
              register(registerInput, "").then(() => window.location.reload())
            }
          >
            Register
          </button>
        </DataRow>
      </DataSection>
    </ModuleContainer>
  );
};
