import { useCallback, useState } from "react";
import { Page } from "../components/Page";
import { InputField } from "../components";
import { Button } from "../components/Button";
import { generateResetPassword } from "../gateways/usersGateway";

export const GenerateResetPasswordPage = () => {
  const [userName, setUserName] = useState("");
  const onResetClick = useCallback(async () => {
    await generateResetPassword(userName);
  }, [userName]);
  return (
    <Page>
      <p>
        Please note, this reset feature is incomplete. If you submit this form,
        a reset link will be generated in our database, however, that link won't
        yet be sent out as we don't have user emails yet. So, unless you know me
        personally, this page may be useless to you.
      </p>
      <p>Sorry for the inconvenience</p>
      <div>
        <h3>Generate Password Reset</h3>
        <InputField
          placeHolder="Username"
          autoCapitalize="none"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <Button onClick={onResetClick}>Generate Link</Button>
      </div>
    </Page>
  );
};
