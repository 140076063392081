import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useUserContext } from "../providers/UserProvider";

const HeaderMenuContainer = styled.div`
  #toggle {
    display: none;
  }

  /**
  Hamburger
**/
  .hamburger {
    position: absolute;
    top: 3.9em;
    left: 3%;
    margin-right: -2em;
    margin-top: -45px;
    width: 3em;
    height: 45px;
    z-index: 5;
    cursor: pointer;
  }

  .hamburger div {
    position: relative;
    width: 2em;
    height: 5px;
    background-color: #ac3a2e;
    margin-top: 5px;
    transition: all 0.3s ease-in-out;
  }

  /**
Nav Styles
**/
  .nav {
    position: fixed;
    width: 100%;
    height: 100%;
    /* background-color: #090e6b; */
    background-image: repeating-linear-gradient(
      0deg,
      #e7eaad 0,
      #e7eaad 2px,
      transparent 0,
      transparent 50%
    );
    background-size: 100px 100px;
    background-color: #dadab0;
    top: -100%;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    transform: scale(0);
  }
  .nav-wrapper {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
  }
  nav {
    text-align: left;
    margin-left: 25%;
    margin-top: 55px;
  }
  nav a {
    position: relative;
    text-decoration: none;
    color: #ac3a2e;
    font-size: 2em;
    display: inline-block;
    margin-bottom: 62px;
    transition: color 0.2s ease-in-out;
    letter-spacing: 1px;
  }
  nav a:before {
    content: "";
    height: 0;
    position: absolute;
    width: 0.25em;
    background-color: white;
    left: -0.5em;
    transition: all 0.2s ease-in-out;
  }
  nav a:hover {
    color: white;
  }
  nav a:hover:before {
    height: 100%;
  }

  /**
Animations
**/
  #toggle:checked + .hamburger .top-bun {
    transform: rotate(-45deg);
    margin-top: 25px;
  }
  #toggle:checked + .hamburger .bottom-bun {
    opacity: 0;
    transform: rotate(45deg);
  }
  #toggle:checked + .hamburger .meat {
    transform: rotate(45deg);
    margin-top: -7px;
  }

  #toggle:checked + .hamburger + .nav {
    top: 0;
    transform: scale(1);
  }
`;

const HEADER_Z_INDEX = 100;

export const HeaderMenu2: React.FC = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { logout, loggedInUser } = useUserContext();
  return (
    <HeaderMenuContainer>
      <input
        id="toggle"
        checked={isChecked}
        onChange={(v) => setIsChecked(v.target.checked)}
        type="checkbox"
      ></input>

      <label
        htmlFor="toggle"
        className="hamburger"
        style={{ zIndex: HEADER_Z_INDEX }}
      >
        <div className="top-bun"></div>
        <div className="meat"></div>
        <div className="bottom-bun"></div>
      </label>

      <div className="nav" style={{ zIndex: HEADER_Z_INDEX - 1 }}>
        <div className="nav-wrapper">
          <nav>
            <Link to="/" onClick={() => setIsChecked(false)}>
              Home Page
            </Link>
            <br />
            <Link to="/about" onClick={() => setIsChecked(false)}>
              About this site
            </Link>
            <br />
            {!!loggedInUser ? (
              <>
                <Link to="/stories" onClick={() => setIsChecked(false)}>
                  Your Stories
                </Link>
                <br />
                <Link to="/user-dashboard" onClick={() => setIsChecked(false)}>
                  Your Profile/Friends
                </Link>
                <br />
                <Link
                  to="/"
                  onClick={() => {
                    logout();
                    setIsChecked(false);
                  }}
                >
                  Logout
                </Link>
              </>
            ) : (
              <>
                <Link to="/login-register" onClick={() => setIsChecked(false)}>
                  Login/Register
                </Link>
              </>
            )}
          </nav>
        </div>
      </div>
    </HeaderMenuContainer>
  );
};
