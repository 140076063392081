import styled from "styled-components";
import { useStories } from "../../hooks/useStories";

const ModuleContainer = styled.div``;
const ModuleHeader = styled.div``;
const StoriesContainer = styled.div``;
const SelectedStoryDetails = styled.div``;
const NewStorySection = styled.div``;
const CurrentPageContainer = styled.div``;
const StoryBlock = styled.div``;
const StoryBlockTitle = styled.div``;

export const StoriesModule: React.FC = () => {
  const { value: stories } = useStories();
  return (
    <ModuleContainer>
      <ModuleHeader>Stories Module</ModuleHeader>
      <StoriesContainer>
        {stories?.map((s, sI) => (
          <StoryBlock key={sI}>
            <StoryBlockTitle></StoryBlockTitle>
          </StoryBlock>
        ))}
      </StoriesContainer>
      <SelectedStoryDetails></SelectedStoryDetails>
      <NewStorySection></NewStorySection>
      <CurrentPageContainer></CurrentPageContainer>
    </ModuleContainer>
  );
};
