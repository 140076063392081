import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useBackButton } from "../components/Header";
import { Page } from "../components/Page";
import {
  useAddFriend,
  useFriends,
  useRemoveFriend,
  useUsers,
} from "../hooks/useUsers";
import { User } from "../models/gateway";
import { useToast } from "../providers/ToastProvider";
import { useUserContext } from "../providers/UserProvider";

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const UserEntry = styled.div`
  display: flex;
  flex-direction: row;
`;
const UserAction = styled.div`
  cursor: pointer;
  color: blue;
`;

export const UserList: React.FC = () => {
  useBackButton({
    show: true,
  });
  const [refreshKey, setRefreshKey] = useState(0);
  const { loggedInUser } = useUserContext();
  const { loading: usersLoading, value: userList } = useUsers();
  const { showToast } = useToast();
  const { loading: friendsLoading, value: friendList } = useFriends(refreshKey);
  const [, addFriend] = useAddFriend(
    () => setRefreshKey((r) => r + 1),
    (err) => showToast(JSON.stringify(err))
  );
  const [, removeFriend] = useRemoveFriend(
    () => setRefreshKey((r) => r + 1),
    (err) => showToast(JSON.stringify(err))
  );
  const [nonFriends, setNonFriends] = useState<User[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !usersLoading &&
      !friendsLoading &&
      userList &&
      friendList &&
      loggedInUser?.id
    ) {
      const nonFriends = userList.filter(
        (uL) =>
          !friendList.find((f) => f.id === uL.id) && uL.id !== loggedInUser.id
      );
      setNonFriends(nonFriends);
    }
  }, [usersLoading, friendsLoading, userList, friendList, loggedInUser?.id]);

  useEffect(() => {
    if (!loggedInUser) {
      navigate("/");
    }
  }, [loggedInUser, navigate]);
  if (!loggedInUser) {
    return null;
  }

  return (
    <Page>
      <h3>User list</h3>
      <ListContainer>
        <h3>Friends</h3>
        {friendList?.map((u, i) => (
          <UserEntry key={`Friend_${i}`}>
            {u.userName} (
            <UserAction onClick={() => removeFriend({ friendId: u.id })}>
              kill friend
            </UserAction>
            )
          </UserEntry>
        ))}
        <h3>Not friends</h3>
        {nonFriends?.map((u, i) => (
          <UserEntry key={`Non_Friend_${i}`}>
            {u.userName} (
            <UserAction onClick={() => addFriend({ friendId: u.id })}>
              add friend
            </UserAction>
            )
          </UserEntry>
        ))}
      </ListContainer>
    </Page>
  );
};
