import React from "react";
import styled from "styled-components";
import { Breakpoints } from "../ui/Breakpoints";

const TheButton = styled.button`
  line-height: 30px;
  border: 3px solid black;
  border-radius: 5px;
  padding: 0 10px;
  background-color: #ffff;
  :hover {
    cursor: pointer;
    background-color: #fff9;
  }

  min-width: 100%;
  @media (min-width: ${Breakpoints.mobileBig}px) {
    min-width: 200px;
  }
`;
const ButtonContainer = styled.div`
  padding: 10px 0;
`;
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <ButtonContainer>
      <TheButton {...props}></TheButton>
    </ButtonContainer>
  );
};
