import React, { useState } from "react";
import styled from "styled-components";
import { InputField } from "../components";
import { Button } from "../components/Button";
import { useBackButton } from "../components/Header";
import { Page } from "../components/Page";
import { StoriesModule } from "../components/StoriesModule";
import { isLoggedIn } from "../gateways/usersGateway";
import { useCreateStory, useMyStories } from "../hooks/useStories";
import { Privilege } from "../models/gateway";
import { useToast } from "../providers/ToastProvider";

const CreateStorySection = styled.div`
  border: black solid 1px;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
`;
const CreateStoryRow = styled.h1`
  text-align: center;
  font-size: medium;
  text-decoration: underline;
`;
const InputTextSection = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  max-width: 300px;
`;
const InputSectionLabel = styled.span`
  margin: auto 0;
`;

const PermissionDropDown: React.FC<{
  selection: Privilege;
  onSelection: (selection: Privilege) => void;
}> = ({ onSelection, selection }) => {
  const privileges = Object.values(Privilege);
  return (
    <select
      style={{
        margin: "auto",
      }}
      value={selection}
      onChange={(e) => {
        onSelection(e.target.value as Privilege);
      }}
    >
      {privileges.map((p, i) => (
        <option key={i} value={p}>
          {p}
        </option>
      ))}
    </select>
  );
};

const StoryDashboardCreateStory: React.FC<{
  setAddedClicks: React.Dispatch<React.SetStateAction<number>>;
}> = (props) => {
  const { setAddedClicks } = props;
  const toast = useToast();
  const [storyUpsert, setStoryUpsert] = useState<Privilege>(Privilege.Private);
  const [storyDelete, setStoryDelete] = useState<Privilege>(Privilege.Private);
  const [pageUpsert, setPageUpsert] = useState<Privilege>(Privilege.ByCreator);
  const [pageDelete] = useState<Privilege>(Privilege.ByCreator);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [, createStory] = useCreateStory(
    () => setAddedClicks((v) => v + 1),
    (err) => toast.showToast(JSON.stringify(err))
  );
  return (
    <CreateStorySection>
      <CreateStoryRow>New Story</CreateStoryRow>
      <InputTextSection>
        <InputField
          placeHolder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <InputField
          placeHolder="Summary"
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
        />
      </InputTextSection>
      <h3>The following default permissions are recommended</h3>
      <InputSection>
        <InputSectionLabel>Story Update permissions: </InputSectionLabel>
        <PermissionDropDown
          onSelection={setStoryUpsert}
          selection={storyUpsert}
        />
        <InputSectionLabel>Story Delete permissions: </InputSectionLabel>
        <PermissionDropDown
          onSelection={setStoryDelete}
          selection={storyDelete}
        />
        <InputSectionLabel>Page Create/Update permissions: </InputSectionLabel>
        <PermissionDropDown
          onSelection={setPageUpsert}
          selection={pageUpsert}
        />
      </InputSection>
      <Button
        onClick={() =>
          createStory({
            title,
            summary,
            deletePagePrivilege: pageDelete,
            deleteStoryPrivilege: storyDelete,
            upsertPagePrivilege: pageUpsert,
            upsertStoryPrivilege: storyUpsert,
            isDraft: false,
          })
        }
      >
        Create
      </Button>
    </CreateStorySection>
  );
};

export const StoriesDashboard: React.FC = () => {
  useBackButton({
    show: true,
  });
  const [addedClicks, setAddedClicks] = useState(0);
  const { value, loading } = useMyStories(addedClicks);
  const canCreateStory = isLoggedIn();
  return (
    <Page>
      {canCreateStory && (
        <StoryDashboardCreateStory setAddedClicks={setAddedClicks} />
      )}
      {((value?.length || loading) && (
        <StoriesModule storyList={value} isLoading={loading} />
      )) ||
        "You have none yet, but you should make one! (make sure to login/register first)"}
    </Page>
  );
};
