import { useAsync, useAsyncFn } from "react-use";
import {
  createStory,
  fetchAllStories,
  fetchMyStories,
  fetchStoryById,
  updateStory,
} from "../gateways/storiesGateway";
import { CreateStoryPayload, Story } from "../models/gateway";

export const useStories = (key?: number) => {
  return useAsync(async () => {
    return await fetchAllStories();
  }, [key]);
};

export const useStoryById = (id: string, key?: number) => {
  return useAsync(async () => {
    return await fetchStoryById(id);
  }, [key]);
};

export const useMyStories = (key?: number) => {
  return useAsync(async () => {
    return await fetchMyStories();
  }, [key]);
};

export const useCreateStory = (
  onSucess?: () => void,
  onError?: (error: Error) => void
) => {
  return useAsyncFn(async (story: CreateStoryPayload) => {
    try {
      await createStory(story);
      onSucess && onSucess();
    } catch (err) {
      onError && onError(err as Error);
    }
  });
};

export const useUpdateStory = (
  onSuccess: () => void,
  onError: (error: Error) => void
) => {
  return useAsyncFn(async (story: Story) => {
    try {
      await updateStory(story);
      onSuccess();
    } catch (err) {
      onError(err as Error);
    }
  });
};
