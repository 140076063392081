import { useAsync, useAsyncFn } from "react-use";
import { getPage, updatePageContent } from "../gateways/pagesGateway";

export const usePage = (pageId: string, key?: number) => {
  return useAsync(async () => {
    return await getPage(pageId);
  }, [pageId, key]);
};

export const usePageUpdateContent = (
  onSucess: () => void,
  onError: (error: Error) => void
) => {
  return useAsyncFn(async (story: { text: string; pageId: string }) => {
    try {
      await updatePageContent(story.pageId, story.text);
      onSucess();
    } catch (err) {
      onError(err as Error);
    }
  });
};
