import { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";

export type ToastContextValue = {
  showToast: (message: string) => void;
};

export const ToastContext = createContext<ToastContextValue | undefined>(
  undefined
);

const ToastContainer = styled.div`
  z-index: 1000;
  background: #fffe;
  position: fixed;
  width: 80%;
  left: 10%;
  text-align: center;
  padding: 10px 0;
`;

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (message: string) => {
    setToastMessage(message);
    setToastVisible(true);
  };
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (toastVisible) {
      timer = setTimeout(() => setToastVisible(false), 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [toastVisible]);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {toastVisible && <ToastContainer>{toastMessage}</ToastContainer>}
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const client = useContext(ToastContext);
  if (!client) {
    throw new Error("Context not initialized");
  }
  return client;
};
