import React from "react";
import styled from "styled-components";
import { StoriesModule } from "./StoriesModule";
import { UserModule } from "./UserModule";

const DemoContainer = styled.div`
  display: inline-block;
`;

export const DemoPage: React.FC = () => {
  return (
    <DemoContainer>
      <UserModule />
      <StoriesModule />
    </DemoContainer>
  );
};
