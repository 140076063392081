export enum Privilege {
  Private = "Private",
  ByCreator = "By Creator",
  FriendsOnly = "Friends Only",
  Public = "Public",
}

export type Story = {
  id: string;
  title: string;
  createdByUsername?: string;
  createdById: string;
  summary?: string;
  firstPageId?: string;
  isDraft: boolean;
  upsertStoryPrivilege: Privilege;
  upsertPagePrivilege: Privilege;
  deleteStoryPrivilege: Privilege;
  deletePagePrivilege: Privilege;
};

export type GetStoryResponse = Story;

export type CreateStoryPayload = {
  title: string;
  summary?: string;
  isDraft: boolean;
  upsertStoryPrivilege: Privilege;
  upsertPagePrivilege: Privilege;
  deleteStoryPrivilege: Privilege;
  deletePagePrivilege: Privilege;
};

export type ListStoriesResponse = Story[];
