import {
  CreateStoryPayload,
  GetStoryResponse,
  ListStoriesResponse,
  Story,
} from "../models/gateway";
import { doApiCall } from "../utils/apiUtils";
import { getLoggedIn } from "./usersGateway";

export const fetchStoryById = async (id: string) => {
  return await doApiCall<GetStoryResponse>({
    method: "get",
    url: `dev/story/${id}`,
  });
};

export const fetchMyStories = async () => {
  const user = getLoggedIn();
  if (!user) {
    return [];
  }
  return await doApiCall<ListStoriesResponse>({
    method: "get",
    url: `dev/story/list/${user.id}`,
  });
};

export const fetchStoryByUserId = async (userId: string) => {
  return await doApiCall<ListStoriesResponse>({
    method: "get",
    url: `dev/story/list/${userId}`,
  });
};

export const fetchAllStories = async () => {
  return await doApiCall<ListStoriesResponse>({
    method: "get",
    url: "dev/story/list",
  });
};

export const createStory = async (story: CreateStoryPayload) => {
  return await doApiCall<string>({
    method: "post",
    url: "dev/story/create",
    data: {
      ...story,
    },
  });
};

export const updateStory = async (story: Story) => {
  return await doApiCall<string>({
    method: "post",
    url: "dev/story/update",
    data: story,
  });
};

export const deleteStory = async (id: string) => {
  await doApiCall({
    method: "post",
    url: "dev/story/delete",
    data: {
      id,
    },
  });
};
