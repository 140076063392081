import { useBackButton } from "../components/Header";
import { Page } from "../components/Page";

export const AboutPage: React.FC = () => {
  useBackButton({
    show: true,
  });
  return (
    <Page>
      <h3>Hello! Thank you for visiting my site.</h3>
      <h3>What is this site for?</h3>
      <p>
        For reading creative works from other users. If you feel like expressing
        your creative side, go ahead and start writing a story, whether it'd
        just be a page or an entire story. You can also have fun and help out
        others' stories by adding pages to theirs. You can also branch off a
        page and create your own choice: "Do you go into the scary house (Turn
        to page ..) or do you run away (Turn to page ..)"
      </p>
      <h3>How did this site come to be?</h3>
      <p>
        When I was in elementary school, there was this activity in the computer
        laboratory where we would all sit at a computer and start writing a
        story. After a certain number of minutes, the teacher would say "STOP,
        ROTATE ## seats". We would get up, and go to the next computer ## seats
        away. We would read the previous student's story so far and then start
        adding our own touch to it. At the end, we would return to our seat and
        read all of what other studends had added to our own story
      </p>
      <p>
        I also enjoyed reading the "choose your own adventure" books where you
        would be directed to turn to a specific page if you wanted to do one
        action or turn to another page for a different action.
      </p>
    </Page>
  );
};
