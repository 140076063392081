import { useCallback, useEffect, useState } from "react";
import { Page } from "../components/Page";
import { InputField } from "../components";
import { Button } from "../components/Button";
import { resetPassword } from "../gateways/usersGateway";
import { useParams } from "react-router-dom";

export const ResetPasswordPage = () => {
  const { resetId, userId } = useParams<{
    userId: string;
    resetId: string;
  }>();
  const [password, setPassword] = useState("");
  const onResetClick = useCallback(async () => {
    if (userId && resetId) {
      await resetPassword(userId, resetId, password);
    }
  }, [password]);
  return (
    <Page>
      <div>
        <h3>Password Reset</h3>
        <InputField
          placeHolder="Password"
          autoCapitalize="none"
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={onResetClick}>Update password</Button>
      </div>
    </Page>
  );
};
