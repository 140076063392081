import React, { createContext, useContext, useState } from "react";
import styled from "styled-components";

export type LoaderContextValue = {
  setIsLoading: (isLoading: boolean) => void;
};
export const LoaderContext = createContext<LoaderContextValue | undefined>(
  undefined
);

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #000a;
`;
const AbsoluteLoaderContainer = styled(LoaderContainer)`
  position: absolute;
`;
const LoaderIcon = styled.div`
  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
  ::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #1c87c9;
    border-radius: 50%;
    content: "";
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 40px;
    will-change: transform;
  }
`;

export const LoaderModule: React.FC<{ isAbsolute?: boolean }> = ({
  isAbsolute,
}) => {
  if (isAbsolute) {
    return (
      <AbsoluteLoaderContainer>
        <LoaderIcon />
      </AbsoluteLoaderContainer>
    );
  }
  return (
    <LoaderContainer>
      <LoaderIcon />
    </LoaderContainer>
  );
};

export const LoaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <LoaderContext.Provider value={{ setIsLoading }}>
      {isLoading ? <LoaderModule /> : null}
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("Context not initialized");
  }
  return context;
};
