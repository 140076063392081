import styled from "styled-components";
import RWILogo from "../assets/RWI-logo.png";
import { useNavigate } from "react-router-dom";
import { Story } from "../models/gateway";
import { LoaderModule } from "../providers/LoaderProvider";

const ModuleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-height: 200px;
`;
const StoryBlock = styled.div`
  width: 80px;
  min-height: 80px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background: #fff5;
  }
  margin: 2px;
`;
const BlockBackground = styled.img`
  width: 32px;
  height: auto;
  margin: auto;
`;
const BlockTitle = styled.div`
  margin: auto;
  text-align: center;
`;

export type StoriesModuleProps = {
  storyList: Story[] | undefined;
  isLoading?: boolean;
};

export const StoriesModule: React.FC<StoriesModuleProps> = (props) => {
  const { storyList, isLoading } = props;
  const navigate = useNavigate();
  return (
    <ModuleContainer>
      {isLoading && <LoaderModule isAbsolute={true} />}
      {storyList?.map((s, sI) => (
        <StoryBlock key={sI} onClick={() => navigate(`/story/${s.id}`)}>
          <BlockBackground src={RWILogo} />
          <BlockTitle>{s.title}</BlockTitle>
        </StoryBlock>
      ))}
    </ModuleContainer>
  );
};
