import { useAsync, useAsyncFn } from "react-use";
import {
  listUsers,
  register,
  login,
  listUserFriends,
  addFriend,
  removeFriend,
  changePassword,
} from "../gateways/usersGateway";

export const useUsers = () => {
  return useAsync(async () => {
    return await listUsers();
  }, []);
};

export const useFriends = (key?: number) => {
  return useAsync(async () => {
    return await listUserFriends();
  }, [key]);
};

export const useAddFriend = (
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  return useAsyncFn(async (props: { friendId: string }) => {
    try {
      await addFriend(props.friendId);
      onSuccess && onSuccess();
    } catch (err) {
      onError && onError(err as Error);
    }
  });
};

export const useRemoveFriend = (
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  return useAsyncFn(async (props: { friendId: string }) => {
    try {
      await removeFriend(props.friendId);
      onSuccess && onSuccess();
    } catch (err) {
      onError && onError(err as Error);
    }
  });
};

export const useUserRegistration = (
  onSuccess: () => void,
  onError: (err: Error) => void
) => {
  return useAsyncFn(async (props: { userName: string; password: string }) => {
    try {
      await register(props.userName, props.password);
      onSuccess();
    } catch (err) {
      onError(err as Error);
    }
  });
};

export const useUserChangePassword = (
  onSuccess: () => void,
  onError: (err: Error) => void
) => {
  return useAsyncFn(async (props: { oldPassword: string; newPassword: string }) => {
    try {
      await changePassword(props.oldPassword, props.newPassword);
      onSuccess();
    } catch (err) {
      onError(err as Error);
    }
  });
};

export const useUserLogin = (
  onSuccess?: () => void,
  onError?: (err: Error) => void
) => {
  return useAsyncFn(async (props: { userName: string; password: string }) => {
    try {
      await login(props.userName, props.password);
      onSuccess && onSuccess();
    } catch (err) {
      onError && onError(err as Error);
    }
  });
};
