import { listUserFriends } from "../gateways/usersGateway";
import { Page, Privilege, Story } from "../models/gateway";

export const canUserAddPage = async (props: {
  loggedInId?: string;
  story: Story;
  page?: Page;
}) => {
  const { loggedInId, story, page } = props;
  return await isStoryActionAllowed({
    actionType: "new",
    loggedInId,
    recordType: "page",
    page,
    story,
  });
};
export const canUserUpdatePage = async (props: {
  loggedInId?: string;
  story: Story;
  page?: Page;
}) => {
  const { loggedInId, story, page } = props;
  return await isStoryActionAllowed({
    actionType: "update",
    loggedInId,
    recordType: "page",
    page,
    story,
  });
};
export const canUserDeletePage = async (props: {
  loggedInId?: string;
  story: Story;
  page?: Page;
}) => {
  const { loggedInId, story, page } = props;
  return await isStoryActionAllowed({
    actionType: "delete",
    loggedInId,
    recordType: "page",
    page,
    story,
  });
};
export const canUserUpdateStory = async (props: {
  loggedInId?: string;
  story: Story;
  page?: Page;
}) => {
  const { loggedInId, story, page } = props;
  return await isStoryActionAllowed({
    actionType: "update",
    loggedInId,
    recordType: "story",
    page,
    story,
  });
};
export const canUserDeleteStory = async (props: {
  loggedInId?: string;
  story: Story;
  page?: Page;
}) => {
  const { loggedInId, story, page } = props;
  return await isStoryActionAllowed({
    actionType: "delete",
    loggedInId,
    recordType: "story",
    page,
    story,
  });
};

const testIfFriends = async (storyCreatedById: string) => {
  const friends = await listUserFriends();
  return !!friends.find((f) => f.id === storyCreatedById);
};

const isStoryActionAllowed = async (input: {
  actionType: "new" | "update" | "delete"; // 'new' is only used for page
  recordType: "story" | "page";
  page?: Page;
  story: Story;
  loggedInId?: string;
}) => {
  const { actionType, loggedInId, recordType, story, page } = input;
  const pageCreatedById = page?.createdById;
  const {
    createdById: storyCreatedById,
    deletePagePrivilege,
    deleteStoryPrivilege,
    upsertPagePrivilege,
    upsertStoryPrivilege,
  } = story;
  // TODO: Would like to allow for anon creations in future
  if (!loggedInId) {
    return false;
  }
  if (loggedInId === storyCreatedById) {
    return true;
  }
  if (actionType === "delete") {
    if (recordType === "page") {
      if (deletePagePrivilege === Privilege.Public) {
        return true;
      } else if (deletePagePrivilege === Privilege.FriendsOnly) {
        return await testIfFriends(storyCreatedById);
      } else if (deletePagePrivilege === Privilege.ByCreator) {
        return !!pageCreatedById && loggedInId === pageCreatedById;
      }
    } else {
      if (deleteStoryPrivilege === Privilege.Public) {
        return true;
      } else if (deleteStoryPrivilege === Privilege.FriendsOnly) {
        return await testIfFriends(storyCreatedById);
      }
    }
  } else {
    if (recordType === "page") {
      if (upsertPagePrivilege === Privilege.Public) {
        return true;
      } else if (upsertPagePrivilege === Privilege.FriendsOnly) {
        return await testIfFriends(storyCreatedById);
      } else if (upsertPagePrivilege === Privilege.ByCreator) {
        if (actionType === "update") {
          return !!pageCreatedById && loggedInId === pageCreatedById;
        } else {
          return true;
        }
      }
    } else {
      if (upsertStoryPrivilege === Privilege.Public) {
        return true;
      } else if (upsertStoryPrivilege === Privilege.FriendsOnly) {
        return await testIfFriends(storyCreatedById);
      }
    }
  }
  return false;
};
