import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useBackButton } from "../components/Header";
import { Page } from "../components/Page";
import { StoriesModule } from "../components/StoriesModule";
import { useStories } from "../hooks/useStories";

const DashboardContainer = styled(Page)`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

export const Dashboard: React.FC = () => {
  useBackButton({
    show: false,
  });
  const { value } = useStories();
  return (
    <DashboardContainer>
      <h1 style={{ textAlign: "center" }}>
        Dashboard
        <br />(<Link to="/about">about this site</Link>)
      </h1>
      <Link to={"/stories"}>Your Story Station</Link>
      <h3>Popular stories</h3>
      {(value?.length && <StoriesModule storyList={value} />) ||
        "None yet! You should make one! (make sure to login/register first)"}
    </DashboardContainer>
  );
};
