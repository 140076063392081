import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useBackButton } from "../components/Header";
import { Page } from "../components/Page";
import { deleteStory } from "../gateways/storiesGateway";
import { useAuthUtils } from "../hooks/useAuthUtils";
import { useStoryById, useUpdateStory } from "../hooks/useStories";
import { useToast } from "../providers/ToastProvider";
import { useUserContext } from "../providers/UserProvider";

const DetailsContainer = styled(Page)`
  padding: 5px;
`;
const DetailLine = styled.div`
  margin-bottom: 5px;
`;
const SummaryEditButton = styled.button``;
const SummaryEditSection = styled.div``;
const EditButtonContainer = styled.div``;

const SummaryTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const EditableField: React.FC<{
  title: string;
  value: string;
  canEdit: boolean;
  onSubmit: (value: string) => void;
}> = (props) => {
  const { title, value, onSubmit, canEdit } = props;
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [editText, setEditText] = useState(value);
  return (
    <DetailLine>
      {!isInEditMode ? (
        <>
          {title}: {value}
          {canEdit && (
            <>
              {" "}
              <SummaryEditButton onClick={() => setIsInEditMode(true)}>
                edit
              </SummaryEditButton>
            </>
          )}
        </>
      ) : (
        <SummaryEditSection>
          <SummaryTextArea
            onChange={(e) => setEditText(e.target.value)}
            value={editText}
          ></SummaryTextArea>
          <EditButtonContainer>
            <button
              onClick={() => {
                setIsInEditMode(false);
                onSubmit(editText);
              }}
            >
              Submit
            </button>
            <button
              onClick={() => {
                setEditText(value);
                setIsInEditMode(false);
              }}
            >
              Cancel
            </button>
          </EditButtonContainer>
        </SummaryEditSection>
      )}
    </DetailLine>
  );
};

export const StoryDetailsPage: React.FC = () => {
  useBackButton({
    show: true,
  });
  const { storyId } = useParams<{ storyId: string }>();
  const [storyKey, setStoryKey] = useState(0);
  const { value: story } = useStoryById(storyId || "", storyKey);
  const { loggedInUser } = useUserContext();
  const { value: authUtils } = useAuthUtils(loggedInUser, story);
  const { showToast } = useToast();
  const [, updateStory] = useUpdateStory(
    () => {
      setStoryKey((k) => k + 1);
    },
    (err) => showToast(JSON.stringify(err))
  );
  const navigate = useNavigate();
  const onDelete = () => {
    if (storyId) {
      deleteStory(storyId)
        .then((e) => navigate("/stories"))
        .catch((err) => showToast(JSON.stringify(err)));
    }
  };
  if (!story || !authUtils) {
    return null;
  }
  const { canUserDeleteStory, canUserAddPage, canUserUpdateStory } = authUtils;
  return (
    <DetailsContainer>
      <EditableField
        title="Title"
        canEdit={canUserUpdateStory}
        value={story.title}
        onSubmit={(newValue) =>
          updateStory({
            ...story,
            title: newValue,
            summary: story.summary,
          })
        }
      />
      <EditableField
        title="Summary"
        canEdit={canUserUpdateStory}
        value={story.summary || ""}
        onSubmit={(newSummary) =>
          updateStory({
            ...story,
            title: story.title,
            summary: newSummary,
          })
        }
      />
      <DetailLine>Started by: {story.createdByUsername}</DetailLine>
      <DetailLine>
        Story Update permissions: {story.upsertStoryPrivilege}
      </DetailLine>
      <DetailLine>
        Story Delete permissions: {story.deleteStoryPrivilege}
      </DetailLine>
      <DetailLine>
        Page Create/Update permissions: {story.upsertPagePrivilege}
      </DetailLine>
      <DetailLine>
        Page Delete permissions: {story.deletePagePrivilege}
      </DetailLine>
      {story.firstPageId ? (
        <button
          onClick={() => navigate(`/page/${story.id}/${story.firstPageId}`)}
        >
          Start Reading
        </button>
      ) : canUserAddPage ? (
        <button onClick={() => navigate(`/new-page/${story.id}`)}>
          Create first page
        </button>
      ) : (
        <span>
          Owner hasn't created first page yet nor do you yet have access to
          creating one on this story. Feel free to either read this story or to
          jump on another one!!
        </span>
      )}
      {canUserDeleteStory && <button onClick={() => onDelete()}>Delete</button>}
    </DetailsContainer>
  );
};
