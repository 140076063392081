import axios from "axios";
import { getBearerToken } from "../gateways/usersGateway";

export type ApiCallProps = {
  method?: "post" | "get";
  url: string;
  data?: { [key: string]: string | number | boolean | null | undefined };
};

export const doApiCall = async <T>(props: ApiCallProps): Promise<T> => {
  const { data, method, url } = props;
  const token = getBearerToken();
  try {
    const response = await axios({
      // baseURL: "http://localhost:3000/",
      // baseURL: "http://192.168.1.50:3000/",
      baseURL: "https://pmrg4ecemc.execute-api.us-east-2.amazonaws.com/",
      method: method || "get",
      url,
      data,
      headers: {
        ...(token ? { Authorization: token } : {}),
        "Content-Type": "application/json",
      },
    });
    return response.data as T;
  } catch (err: any) {
    if (err?.response?.data?.message) {
      throw err.response.data.message;
    } else if (err?.response?.data) {
      throw err.response.data;
    } else if (err?.response) {
      console.log("2", JSON.stringify(err.response));
      throw err.response;
    } else {
      console.log("3", JSON.stringify(err));
      throw err;
    }
  }
};
