import { doApiCall } from "../utils/apiUtils";

export const createChoice = async (text: string, fromId: string) => {
  return await doApiCall<string>({
    method: "post",
    url: "dev/choice/create",
    data: {
      fromId,
      text,
    },
  }); // choiceId
};

export const updateChoice = async (text: string, choiceId: string) => {
  return await doApiCall<string>({
    method: "post",
    url: "dev/choice/update",
    data: {
      choiceId,
      text,
    },
  }); // choiceId
};