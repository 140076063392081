import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBackButton } from "../components/Header";
import { Page } from "../components/Page";
import { createPage } from "../gateways/pagesGateway";
import { useToast } from "../providers/ToastProvider";

export const CreatePagePage: React.FC = () => {
  useBackButton({
    show: true,
  });
  const { prevPageId, storyId, choiceId } = useParams<{
    storyId: string;
    prevPageId?: string;
    choiceId?: string;
  }>();
  const [text, setText] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const onChoice = () => {
    if (storyId) {
      createPage(storyId, text, prevPageId, choiceId)
        .then((pageId) => {
          navigate(`/page/${storyId}/${pageId}`, { state });
        })
        .catch((err) => showToast(JSON.stringify(err)));
    }
  };
  return (
    <Page>
      {/* Test: {`sid: ${storyId} prevId: ${prevPageId}`}
      <br /> */}
      New page text:{" "}
      <input value={text} onChange={(e) => setText(e.target.value)} />
      <button onClick={onChoice}>Submit</button>
    </Page>
  );
};
