import React, { createContext, useContext, useEffect, useState } from "react";
import { getLoggedIn, logout } from "../gateways/usersGateway";
import { useUserLogin } from "../hooks/useUsers";
import { User } from "../models/gateway";
import { useToast } from "./ToastProvider";

export type UserContextValue = {
  loggedInUser: User | null;
  login: (props: { userName: string; password: string }) => void;
  logout: () => void;
};

export const UserContext = createContext<UserContextValue | undefined>(
  undefined
);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { showToast } = useToast();
  const [loggedInUser, setLoggedInUser] = useState<User | null>(getLoggedIn());
  useEffect(() => {
    setInterval(() => {
      setLoggedInUser(getLoggedIn());
    }, 5000);
  }, []);
  const [, login] = useUserLogin(
    () => setLoggedInUser(getLoggedIn()),
    (err) => showToast(JSON.stringify(err))
  );
  const doLogin = (input: { userName: string; password: string }) => {
    login(input);
  };
  const doLogout = () => {
    logout();
    setLoggedInUser(null);
  };
  return (
    <UserContext.Provider
      value={{ loggedInUser, login: doLogin, logout: doLogout }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Context not initialized");
  }
  return context;
};
