export enum AuthorityLevel {
  user = "user",
  admin = "admin",
  superAdmin = "super-admin",
}

export type User = {
  id: string;
  userName: string;
  authority: AuthorityLevel;
};

export type ListUsersResponse = User[];
