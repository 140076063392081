import { ListUsersResponse, User } from "../models/gateway";
import jwt from "jwt-decode";
import { doApiCall } from "../utils/apiUtils";

export const listUsers = async () => {
  return await doApiCall<ListUsersResponse>({
    method: "get",
    url: "dev/user/list",
  });
};

export const listUserFriends = async () => {
  return await doApiCall<ListUsersResponse>({
    method: "get",
    url: "dev/user/list-friends",
  });
};

export const addFriend = async (friendId: string) => {
  return await doApiCall<ListUsersResponse>({
    method: "post",
    url: "dev/user/add-friend",
    data: { friendId },
  });
};

export const removeFriend = async (friendId: string) => {
  return await doApiCall<ListUsersResponse>({
    method: "get",
    url: `dev/user/remove-friend/${friendId}`,
  });
};

export const getLoggedIn = (): User | null => {
  if (!isLoggedIn()) {
    return null;
  }
  const encodedJWT = window.localStorage.getItem("login");
  if (!encodedJWT) {
    return null;
  }
  return (jwt(encodedJWT) as any)?.["user"] || null;
};

export const getBearerToken = () => {
  const encodedJWT = window.localStorage.getItem("login");
  if (!encodedJWT) {
    return null;
  }
  return `Bearer ${encodedJWT}`;
};

export const isJWTExpired = (exp: number) => {
  return Date.now() >= exp * 1000;
};

export const getJWTExpireTime = () => {
  const encodedJWT = window.localStorage.getItem("login");
  if (!encodedJWT) {
    return "";
  }
  const decodedJWT = jwt(encodedJWT) as any;
  if (decodedJWT) {
    return new Date(decodedJWT.exp * 1000).toString();
  }
  return "";
};

export const isLoggedIn = (): boolean => {
  const encodedJWT = window.localStorage.getItem("login");
  if (!encodedJWT) {
    return false;
  }
  const decodedJWT = jwt(encodedJWT) as any;
  if (decodedJWT && decodedJWT.user && !isJWTExpired(decodedJWT.exp)) {
    return true;
  }
  return false;
};

export const login = async (userName: string, password: string) => {
  const jsonData = await doApiCall<string>({
    method: "post",
    url: "dev/user/login",
    data: { userName, password },
  });
  window.localStorage.setItem("login", jsonData);
};

export const logout = () => {
  window.localStorage.removeItem("login");
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  await doApiCall<void>({
    method: "post",
    url: "dev/user/change-password",
    data: { oldPassword, newPassword },
  });
};

export const register = async (userName: string, password: string) => {
  return await doApiCall<string>({
    method: "post",
    url: "dev/user/register",
    data: { userName, password },
  });
};

export const generateResetPassword = async (userName: string) => {
  return await doApiCall<boolean>({
    method: "post",
    url: "dev/user/generate-reset-password",
    data: { userName },
  });
};

export const resetPassword = async (
  userId: string,
  resetId: string,
  newPassword: string
) => {
  return await doApiCall<boolean>({
    method: "post",
    url: "dev/user/reset-password",
    data: { userId, resetId, newPassword },
  });
};
