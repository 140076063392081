import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { InputField } from "../components";
import { Button } from "../components/Button";
import { useBackButton } from "../components/Header";
import { Page } from "../components/Page";
import { useFriends, useUserChangePassword } from "../hooks/useUsers";
import { useToast } from "../providers/ToastProvider";
import { useUserContext } from "../providers/UserProvider";

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const DetailLabel = styled.div`
  margin-right: 10px;
`;
const DetailValue = styled.div``;
const FriendsList = styled.div`
  display: flex;
  flex-direction: column;
`;
const FriendEntry = styled.div``;

const ChangePasswordComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [oldPassword, setOldPW] = useState("");
  const [newPassword, setNewPW] = useState("");
  const { showToast } = useToast();
  const [, changePW] = useUserChangePassword(
    () => {
      showToast("Success!");
      setIsOpen(false);
    },
    (err) => {
      showToast(JSON.stringify(err));
    }
  );
  return (
    <div>
      <div>
        {isOpen ? (
          <div style={{ border: "1px solid black" }}>
            <h3>PASSWORD CHANGE</h3>
            <InputField
              placeHolder="Old Password"
              autoCapitalize="none"
              type={"password"}
              value={oldPassword}
              onChange={(e) => setOldPW(e.target.value)}
            />
            <InputField
              placeHolder="New Password"
              autoCapitalize="none"
              type={"password"}
              value={newPassword}
              onChange={(e) => setNewPW(e.target.value)}
            />
            <Button onClick={() => changePW({ oldPassword, newPassword })}>
              Save
            </Button>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          </div>
        ) : (
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              setOldPW("");
              setNewPW("");
              setIsOpen(true);
            }}
          >
            ChangePassword
          </span>
        )}
      </div>
    </div>
  );
};

export const UserDashboard: React.FC = () => {
  useBackButton({
    show: true,
  });
  const { loggedInUser } = useUserContext();
  const { loading: friendsLoading, value } = useFriends();
  const navigate = useNavigate();
  useEffect(() => {
    if (!loggedInUser) {
      navigate("/");
    }
  }, [loggedInUser, navigate]);
  if (!loggedInUser) {
    return null;
  }
  return (
    <Page>
      <h1>Your profile</h1>
      <ChangePasswordComponent />
      <h3>Your details</h3>
      <DetailRow>
        <DetailLabel>UserName: </DetailLabel>
        <DetailValue>{loggedInUser.userName}</DetailValue>
      </DetailRow>
      <h3>
        Your friends (<Link to="/user-list">find friends</Link>)
      </h3>
      {friendsLoading ? (
        <>Loading...</>
      ) : value?.length ? (
        <FriendsList>
          {value.map((f, i) => (
            <FriendEntry key={i}>{f.userName}</FriendEntry>
          ))}
        </FriendsList>
      ) : (
        <>No friends...</>
      )}
    </Page>
  );
};
