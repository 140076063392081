import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useBackButton } from "../components/Header";
import { Page } from "../components/Page";
import { createChoice } from "../gateways/choicesGateway";
import { useAuthUtils } from "../hooks/useAuthUtils";
import { useUpdateChoice } from "../hooks/useChoices";
import { usePage, usePageUpdateContent } from "../hooks/usePages";
import { useStoryById } from "../hooks/useStories";
import { useToast } from "../providers/ToastProvider";
import { useUserContext } from "../providers/UserProvider";

const ChoicesSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  width: fit-content;
`;
const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2px 0;
`;
const ChoiceText = styled.div`
  border: black 1px solid;
  border-radius: 5px;
  padding: 5px;
  min-width: 50px;
  text-align: center;
`;
const ChoiceEdit = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  margin-left: 5px;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
`;

type PageState = {
  firstPageId?: string;
};
const PageContentContainer = styled.div``;
const PageContentEditButton = styled.button``;
const PageContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const EditTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
const ButtonContainer = styled.div``;

export const PageDetailsPage: React.FC = () => {
  useBackButton({
    show: true,
  });
  const { pageId, storyId } = useParams<{ storyId: string; pageId: string }>();
  const [key, setKey] = useState(0);
  const { value: page } = usePage(pageId || "", key);
  const { value: story } = useStoryById(storyId || "");
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showToast } = useToast();
  const { loggedInUser } = useUserContext();
  const [, updatePageContent] = usePageUpdateContent(
    () => {
      setIsInEditMode(false);
      setKey((k) => k + 1);
    },
    (err) => showToast(JSON.stringify(err))
  );
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [, updateChoice] = useUpdateChoice(() => {
    setKey((k) => k + 1);
  });
  const pageState = state as PageState;
  const onCreateChoice = () => {
    const choiceText = prompt("Enter text for choice");
    if (choiceText) {
      createChoice(choiceText, pageId || "").then(() => {
        setKey((k) => k + 1);
      });
    } else {
      console.log("You must try again with text");
    }
  };
  const [editText, setEditText] = useState(page?.text || "");
  useEffect(() => {
    if (isInEditMode) {
      setEditText(page?.text || "");
    }
  }, [isInEditMode, page?.text]);
  const { value: authUtils } = useAuthUtils(loggedInUser, story, page);
  if (!page || !story || !authUtils) {
    return null;
  }
  const { canUserUpdatePage, canUserAddPage } = authUtils;
  return (
    <Page>
      {pageState?.firstPageId && (
        <>
          <Link to={`/page/${page.storyId}/${pageState.firstPageId}`}>
            Back to first page
          </Link>
          <br />
        </>
      )}
      {!isInEditMode ? (
        <PageContentContainer>
          {canUserUpdatePage && (
            <PageContentEditButton onClick={() => setIsInEditMode(true)}>
              Edit Page Content
            </PageContentEditButton>
          )}
          <PageContent>Content: {page.text}</PageContent>
        </PageContentContainer>
      ) : (
        <>
          <EditTextArea
            onInput={(e) => {
              // this.style.height = "";
              // this.style.height = this.scrollHeight + "px";
            }}
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
          ></EditTextArea>
          <ButtonContainer>
            <button
              onClick={() =>
                updatePageContent({ text: editText, pageId: page.id })
              }
            >
              Done
            </button>
            <button onClick={() => setIsInEditMode(false)}>Cancel</button>
          </ButtonContainer>
        </>
      )}
      <br />
      <ChoicesSection>
        {page.choices.map((c) => (
          <ChoiceContainer key={c.id}>
            <ChoiceText
              onClick={() =>
                c.toId
                  ? navigate(`/page/${page.storyId}/${c.toId}`, {
                      state: { firstPageId: pageState?.firstPageId || page.id },
                    })
                  : navigate(`/new-page/${page.storyId}/${page.id}/${c.id}`, {
                      state: { firstPageId: pageState?.firstPageId || page.id },
                    })
              }
            >
              {c.text}
            </ChoiceText>
            {canUserUpdatePage && (
              <ChoiceEdit
                onClick={() => {
                  const newText = prompt("New text:", c.text);
                  if (newText) {
                    updateChoice({ choiceId: c.id, text: newText });
                  }
                }}
              >
                Edit Choice
              </ChoiceEdit>
            )}
          </ChoiceContainer>
        ))}
      </ChoicesSection>
      {canUserAddPage && (
        <button onClick={onCreateChoice}>Create Choice</button>
      )}
    </Page>
  );
};
