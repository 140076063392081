import { useAsyncFn } from "react-use";
import { updateChoice } from "../gateways/choicesGateway";
import { UpdateChoicePayload } from "../models/gateway";

export const useUpdateChoice = (onSucess: () => void) => {
  return useAsyncFn(async (choice: UpdateChoicePayload) => {
    await updateChoice(choice.text, choice.choiceId);
    onSucess();
  });
};
